

































import {Component, Vue} from 'vue-property-decorator';
import VueElementLoading from 'vue-element-loading';
import store from "@/services/store";
import PowerBiBTab from "@/components/c-power-bi-iframe/BTab.vue";
import PowerBiIframe from "@/components/c-power-bi-iframe/Iframe.vue";


@Component({
    components: {
        'loading': VueElementLoading,
        'PowerBiBTab': PowerBiBTab,
        'PowerBiIframe': PowerBiIframe
    }
})
export default class PurchasePlan extends Vue {
    private region = '00';

    private visible = true

    setVisible(vis: boolean) {
        this.visible = vis
    }

    mounted() {
        this.region = store.state._instanceCode;
    }

}
